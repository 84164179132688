<template>
    <div class="dm_settings_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container">

            <div class="row dm_settings_head_wrapper pt-5">

                <div class="col-md-4 col-12">
                    <h2 class="dm_page_tile mb-4">Details</h2>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                        <a class="nav-link py-4 active" id="dm-dog-details" 
                            href="#dm-pills-dog" role="tab" aria-controls="dm-pills-dog" aria-selected="true"><i
                                class="fas fa-dog pr-4"></i> Dog Details</a>
                        <a class="nav-link py-4" id="dm-owner-details" 
                            href="#dm-pills-owner" role="tab" aria-controls="dm-pills-owner" aria-selected="false"><i
                                class="fas fa-user pr-4"></i> Owner Details</a>
                    </div>
                </div>

                <div class="col-md-8 col-12">
                    <div class="tab-content pt-3" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="dm-pills-dog" role="tabpanel" aria-labelledby="dm-dog-details">
                            <div class="mb-5 d-flex justify-content-between">
                                <div class="dm_settings_head d-flex justify-content-center align-items-center">
                                    <div class="dm_avatar_wrapper">
                                        <div class="dm_avatar">
                                          <img v-if="user.photo" :src="API_URL+user.photo" width="200">
                                          <img v-else src="https://via.placeholder.com/150" alt="">
                                        </div>
                                    </div>
                                    <div class="dm_profile_details pl-4">
                                        <h3>{{ user.dog_name }}</h3>
                                        <h6 class="font-italic">{{ user.first_name }}</h6>
                                    </div>
                                </div>
                            </div>
                            <form action="#" class="dog-details" id="dm_dog_details">
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="dog_name">DOG'S NAME</label>
                                    <input disabled="disabled" type="text" class="form-control h-auto py-3 border-0" id="dog_name" v-model="user.dog_name">
                                    <span class="error_msg alert" v-if="errors.dog_name">
                                        <strong>{{ errors.dog_name }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="breed">DOG'S BREED</label>
                                    <select disabled="disabled" v-model="user.breed" name="breed" class="form-control h-auto py-3 border-0">
                                        <option selected value="">Select breed of your Dog</option>
                                        <option v-for="b in breeds" :key="b.id" :value="b.id">{{ b.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.breed">
                                        <strong>{{ errors.breed }}</strong>
                                    </span>
                                  </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group mb-4 col-md-6">
                                        <label for="gender">GENDER</label>
                                        <select disabled="disabled" id="gender" class="form-control h-auto py-3 border-0" v-model="user.gender">
                                            <option selected value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <span class="error_msg alert" v-if="errors.gender">
                                            <strong>{{ errors.gender }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group mb-4 col-md-6">
                                      <label for="dob">Date of Birth</label>
                                      <input disabled="disabled" type="text" class="form-control h-auto py-3 border-0" id="dob" v-model="user.dob">
                                      <span class="error_msg alert" v-if="errors.dob">
                                          <strong>{{ errors.dob }}</strong>
                                      </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="weight">COUNTRY</label>
                                    <select disabled="disabled" class="form-control h-auto py-3 border-0" v-model="user.country_id" @change="getStateList()">
                                        <option selected value="">Select Country</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.country_id">
                                        <strong>{{ errors.country_id }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="height">STATE</label>
                                    <select disabled="disabled" class="form-control h-auto py-3 border-0" v-model="user.state_id">
                                        <option selected value="">Select State</option>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.state_id">
                                        <strong>{{ errors.state_id }}</strong>
                                    </span>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="height">HEIGHT</label>
                                    <input disabled="disabled" type="text" class="form-control h-auto py-3 border-0" id="height" v-model="user.details.height">
                                    <span class="error_msg alert" v-if="errors.height">
                                        <strong>{{ errors.height }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="weight">WEIGHT</label>
                                    <input disabled="disabled" type="text" class="form-control h-auto py-3 border-0" id="weight" v-model="user.details.weight">
                                    <span class="error_msg alert" v-if="errors.weight">
                                        <strong>{{ errors.weight }}</strong>
                                    </span>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="color">COLOR</label>
                                    <input disabled="disabled" type="text" class="form-control h-auto py-3 border-0" id="color" v-model="user.details.color">
                                    <span class="error_msg alert" v-if="errors.color">
                                        <strong>{{ errors.color }}</strong>
                                    </span>
                                  </div>
                                </div>
                              </form>
                        </div>
                        <div class="tab-pane fade" id="dm-pills-owner" role="tabpanel" aria-labelledby="dm-owner-details">
                          <form action="#" class="owner-details">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="first_name">FIRST NAME</label>
                                <input disabled="disabled" type="text" class="form-control" id="first_name" v-model="user.first_name">
                                <span class="error_msg alert" v-if="errors.first_name">
                                    <strong>{{ errors.first_name }}</strong>
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="last_name">LAST NAME</label>
                                <input disabled="disabled" type="text" class="form-control" id="last_name" v-model="user.last_name">
                                <span class="error_msg alert" v-if="errors.last_name">
                                    <strong>{{ errors.last_name }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="username">USERNAME</label>
                                <input disabled="disabled" type="text" class="form-control" id="username" v-model="user.username">
                                <span class="error_msg alert" v-if="errors.username">
                                    <strong>{{ errors.username }}</strong>
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="email">EMAIL</label>
                                <input disabled="disabled" type="text" class="form-control" id="email" v-model="user.email" readonly>
                                <span class="error_msg alert" v-if="errors.email">
                                    <strong>{{ errors.email }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="phone">phone</label>
                                <input disabled="disabled" type="text" class="form-control" id="phone" v-model="user.phone">
                                <span class="error_msg alert" v-if="errors.phone">
                                    <strong>{{ errors.phone }}</strong>
                                </span>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="tab-pane fade" id="dm-pills-friend-requests" role="tabpanel" aria-labelledby="dm-friend-requests-refrence">
                          <div class="form-row" v-for="(users,index) in requests" v-bind:key="users.id">
                            <div class="avatar">
                              <img :src="API_URL+users.userList[0].photo.name" class="rounded-circle" alt="">
                            </div>
                            <div class="media-body mg-l-10">
                              <h6 class="mg-b-0">{{ users.userList[0].first_name }} {{ users.userList[0].last_name }}</h6>
                              <small class="d-block tx-color-04">{{ users.userList[0].dog_name }}</small>
                            </div>
                            <div class="form-group col-md-6">
                              <button v-if="users.blocked_by && users.blocked_by == user.id" @click="unblockChannel(users.id,index)" class="dm_btn_dark_solid py-2 px-5">UnBlock</button> 
                              <button v-else-if="users.blocked_by" class="dm_btn_dark_solid py-2 px-5">Already Blocked</button> 
                              <button v-else @click="blockChannel(users.id,index)" class="dm_btn_dark_solid py-2 px-5">Block</button> 
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="dm-pills-notification" role="tabpanel" aria-labelledby="dm-notification-refrence">...</div>
                        <div class="tab-pane fade" id="dm-pills-subscription" role="tabpanel" aria-labelledby="dm-subscription-details">...</div>
                        <div class="tab-pane fade" id="dm-pills-help" role="tabpanel" aria-labelledby="dm-help">...</div>
                      </div>
                </div>
                
            </div>
            <!-- /.row -->

        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>
<script>
import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_PROFILE_IMAGE_URL} from '../../constants'

export default {
    name: "settings",
    data: () => ({
      API_URL:API_PROFILE_IMAGE_URL,
      user:[],
      breeds: [],
      countries:[],
      states:[],
      errors:[],
      requests:[],
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue
    },
    methods: {
        async getAuthUser() {
            let form = {
              user_id: localStorage.getItem('details_user_id')
            };
            let responce = await this.$store.dispatch("getSelectedUser", form);
            if (responce.status) {
                responce.data.data.details = window.$.parseJSON(responce.data.data.details);
                this.user = responce.data.data;
                this.getStateList();
            }
        },
        async getBreedList() {
          let responce = await this.$store.dispatch("getBreedList");
          if(responce.status){
              this.breeds = responce.data.data;
          }
        },
        async pendingRequestList() {
          let responce = await this.$store.dispatch("chatRecents");
          if(responce.status){
              this.requests = responce.data.data;
          }
        },
        async getCountryList() {
            let responce = await this.$store.dispatch("getCountryList");
            if (responce.status) {
                this.countries = responce.data.data;
            }
        },
        async getStateList() {
            let form = { country_id : this.user.country_id };
            let responce = await this.$store.dispatch("getStateList", form);
            if (responce.status) {
                this.states = responce.data.data;
            }
        },
        checkDogDetailsForm: function () {
          this.errors = [];

          if (!this.user.dog_name) {
            this.errors.dog_name = 'Dog name is required.';
          }
          if (!this.user.breed) {
            this.errors.breed = 'Dog\'s Breed is required.';
          }
          if (!this.user.gender) {
            this.errors.gender = 'Gender is required.';
          }
          if (!this.user.state_id) {
            this.errors.state_id = 'State is required.';
          }
          if (!this.user.country_id) {
            this.errors.country_id = 'Country is required.';
          }
          if (!this.user.details.height) {
            this.errors.height = 'Height is required.';
          }
          if (!this.user.details.weight) {
            this.errors.weight = 'Weight is required.';
          }
          if (!this.user.details.color) {
            this.errors.color = 'Color is required.';
          }
          if (!this.user.certificate && window.$('#certificate').val() == '' ) {
            this.errors.certificate = 'Certificate is required.';
          }

          if(Object.keys(this.errors) == 0){
            this.updateDogDetail();
          }
        },
        checkOwnerDetailsForm: function () {
          this.errors = [];

          if (!this.user.first_name) {
            this.errors.first_name = 'First name is required.';
          }
          if (!this.user.last_name) {
            this.errors.last_name = 'Last name is required.';
          }
          if (!this.user.username) {
            this.errors.username = 'Username is required.';
          }
          if (!this.user.email) {
            this.errors.email = 'Email is required.';
          }
          if (!this.user.phone) {
            this.errors.phone = 'Phonenumber is required.';
          }

          if(Object.keys(this.errors) == 0){
            this.updateOwnerDetail();
          }
        },
        async updateDogDetail() {
          let form = {
            dog_name: this.user.dog_name,
            breed: this.user.breed,
            gender: this.user.gender,
            dob: this.user.dob,
            country_id: this.user.country_id,
            state_id: this.user.state_id,
            height: this.user.details.height,
            weight: this.user.details.weight,
            color: this.user.details.color,
            certificate: window.$("input[type=file]")[0].files,
          };
          console.log(form);

          let responce = await this.$store.dispatch("submitDogDetailsUpdateForm", form);

          window.notify(responce.data.status, responce.data.message);
          if (responce.data.status == true) {
            window.notify(responce.data.status, responce.data.message);
          }
          return false;
        },
        async updateOwnerDetail() {
          let form = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            username: this.user.username,
            email: this.user.email,
            phone: this.user.phone,
          };

          let responce = await this.$store.dispatch("submitOwnerDetailsUpdateForm", form);

          window.notify(responce.data.status, responce.data.message);
          if (responce.data.status == true) {
            window.notify(responce.data.status, responce.data.message);
          }
          return false;
        },

        async updateFriendRequest(from_id,status,index) {
          let data = {
            from_id : from_id,
            status : status
          };

          this.requests.splice(index, 1);
          let responce = await this.$store.dispatch("updateRequest", data);

          window.notify(responce.data.status, responce.data.message);
          if (responce.data.status == true) {
            window.notify(responce.data.status, responce.data.message);
          }
          return false;
        },

        async blockChannel(channel_id,index) {
          var _this = this;
          let data = {
            chat_channel_id : channel_id,
          };

          let responce = await this.$store.dispatch("blockChannel", data);
          if (responce.data.status == true) {
            _this.requests[index].blocked_by = 1;
            window.notify(responce.data.status, responce.data.message);
          }
        },

        async unblockChannel(channel_id,index) {
          var _this = this;
          let data = {
            chat_channel_id : channel_id,
          };

          let responce = await this.$store.dispatch("unblockChannel", data);
          if (responce.data.status == true) {
            _this.requests[index].blocked_by = null;
            window.notify(responce.data.status, responce.data.message);
          }
        },
    },
    created() {
        this.getAuthUser();
        this.getBreedList();
        this.getCountryList();
        var _this = this;
        setTimeout(function(){
          window.$('#dob').datepicker({
                format: 'yyyy-mm-dd',
                endDate: '+0d',
                autoclose: true
           }); 
          window.$('.nav-pills a').on('click', function () {
            if (_this.$parent.checkSubcription()) {
              window.$(this).tab('show');
            }
          })
        }, 1000);
    }
};
</script>